import React, { useState, useEffect } from "react";
import { db } from "../Firebase/firebase";
import { message } from "flwww";

export const Create = (props) => {
  const [song, setSong] = useState("");
  const [artist, setArtist] = useState("");
  const [name, setName] = useState("");
  const [ded, setDed] = useState("");
  const [bgUrl, setBgURL] = useState("");
  const config = "TCS"; //CM-PB;

  useEffect(() => {
    console.log(db);
    let outletDoc = config === "TCS" ? "settingsTCS" : "settings";
    db.collection("settings")
      .doc(outletDoc)
      .get()
      .then((doc) => {
        console.log(doc);
        setBgURL(doc.data().mobileCustomerImg);
      });
  }, []);

  function onSubmit() {
    if (song === "" || artist === "" || name === "") {
      message("Please fill up song, artist and your name!", "error");
      return;
    } else {
      db.collection("requests").add({
        song,
        artist,
        name,
        dedication: ded,
        createdAt: new Date(),
        confirmed: false,
        hidden: false,
        outlet: config,
      });
      props.history.replace("/confirmation");
    }
  }
  return (
    <div
      style={
        bgUrl !== ""
          ? {
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: `url('${bgUrl}')`,
            }
          : {}
      }
      className="request-page">
      {config === "TCS" ? (
        <img
          id="inverse-logo"
          className="inverted"
          src={require(`../../images/TCS.png`)}></img>
      ) : (
        <img id="inverse-logo" src={require(`../../images/CM-PB.png`)}></img>
      )}

      <div className="request-form">
        <div className="form-group">
          <label htmlFor="song">Song</label>
          <input
            type="text"
            name="song"
            id="song"
            value={song}
            onChange={(e) => setSong(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="artist">Artist</label>
          <input
            type="text"
            name="artist"
            id="artist"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="dedication">Dedication (if any)</label>
          <textarea
            name="dedication"
            id=""
            cols="30"
            rows="10"
            value={ded}
            onChange={(e) => setDed(e.target.value)}></textarea>
        </div>
        <button onClick={onSubmit}>Submit</button>
      </div>
    </div>
  );
};
