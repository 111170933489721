import React, { Component } from "react";
import { auth } from "../Firebase/firebase";
import { message } from "flwww";
import { AppContext } from "../context/AppContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default class AdminLogin extends Component {
  static contextType = AppContext;
  state = {
    email: "spiceoflife019@gmail.com",
    adminEmail: "andreweijie@gmail.com",
    password: "",
    logincode: "1234",
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            this.context.setAdmin(true);
            this.props.history.replace("/admin");
          } else {
            this.context.setAdmin(false);
            this.props.history.replace("/requests");
          }
        });
        this.setState({ user, auth: true });
      } else {
        this.setState({ auth: false });
      }
    });

    /* auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.history.replace("/requests");
        this.setState({ user, auth: true });
      } else {
        this.setState({ auth: false });
      }
    }); */
  }

  makeAdmin = () => {
    const adminData = {
      email: this.state.adminEmail,
    };
    fetch(
      "https://us-central1-ug-song-request.cloudfunctions.net/widgets/addAdmin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(adminData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.message) {
          console.log(data.message);
        } else {
          console.log("Failed");
        }
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    message("logging in...", "success", 2);
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.props.history.replace("/requests");
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
        message("Something went wrong", "error", 4);
      });
  };

  handleSubmitAdmin = (e) => {
    e.preventDefault();
    message("logging in...", "success", 2);
    auth
      .signInWithEmailAndPassword(this.state.adminEmail, this.state.password)
      .then(() => {
        this.props.history.replace("/admin");
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
        message("Something went wrong", "error", 4);
      });
  };
  render() {
    return (
      <div className="login">
        <Tabs>
          <TabList>
            <Tab>Admin</Tab>
          </TabList>

          <TabPanel>
            {/* <button onClick={this.makeAdmin}>make</button> */}
            <form>
              <div style={{ display: "none" }} className="form-group">
                <label>email</label>
                <input
                  type="email"
                  id="email"
                  value={this.state.adminEmail}
                  onChange={this.onChange}></input>
              </div>
              <div className="form-group">
                <label>Login Code</label>
                <input
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.onChange}></input>
              </div>
              <button onClick={this.handleSubmitAdmin}>login</button>
            </form>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}
