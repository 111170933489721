import React, { Component } from "react";
import { Plus, Instagram } from "react-feather";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { db } from "../Firebase/firebase";

export default class Confirmation extends Component {
  static contextType = AppContext;
  state = {
    bgUrl: "",
  };

  componentDidMount() {
    let outletDoc = this.context.outlet === "TCS" ? "settingsTCS" : "settings";
    db.collection("settings")
      .doc(outletDoc)
      .get()
      .then((doc) => {
        this.setState({
          bgUrl: doc.data().mobileCustomerImg,
        });
      });
  }
  render() {
    return (
      <div
        style={
          this.state.bgUrl !== ""
            ? {
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url('${this.state.bgUrl}')`,
              }
            : {}
        }
        className="confirmation-box">
        {this.context.outlet === "TCS" ? (
          <img
            id="inverse-logo"
            className="inverted"
            src={require(`../../images/TCS.png`)}></img>
        ) : (
          <img id="inverse-logo" src={require(`../../images/CM-PB.png`)}></img>
        )}
        <div className="confirmation-text">
          <h1>
            Song request in the queue.<br></br> Enjoy the music!
          </h1>
          <div className="buttons">
            <Link to="/">
              <Plus></Plus> New Request
            </Link>
            <a
              target="_blank"
              href={
                this.context.outlet === "TCS"
                  ? "https://www.instagram.com/thecoastalsettlement/"
                  : "https://www.instagram.com/cmpbdempsey/"
              }>
              <Instagram></Instagram> Visit Socials
            </a>
          </div>
        </div>
      </div>
    );
  }
}
