import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
export default class ShoutOutsAdmin extends Component {
  static contextType = AppContext;
  state = {
    shouts: [],
    newShout: "",
    contentState: null,
  };
  componentDidMount() {
    /*db.collection("shoutouts")
      .where("outlet", "==", this.context.outlet)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState({
          shouts: data,
          /* .filter((item) => !item.confirmed), */
    /*
        });
      });*/
    db.collection("shoutouts")
      .doc(this.context.outlet)
      .get()
      .then((doc) => {
        this.setState({
          editorState: EditorState.createWithContent(
            convertFromRaw(doc.data().content)
          ),
        });
      });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onNotesChange = (e, index) => {
    let newList = [...this.state.shouts];
    newList[index].note = e.target.value;
    this.setState({ shouts: newList });
  };

  onNewChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  addNote = () => {
    db.collection("shoutouts").add({
      createdAt: new Date(),
      note: this.state.newShout,
      outlet: this.context.outlet,
    });
  };

  saveChanges = () => {
    /*     this.state.shouts.map((doc) => {
      db.collection("shoutouts").doc(doc.id).update(doc);
    }); */

    db.collection("shoutouts")
      .doc(this.context.outlet)
      .update({
        lastChanged: new Date(),
        content: convertToRaw(this.state.editorState.getCurrentContent()),
      });
  };
  render() {
    let { shouts } = this.state;
    return (
      <div className="notes-admin-page">
        {/* <textarea id="newShout" onChange={this.onNewChange}></textarea>
        <button onClick={this.addNote}>Add</button>
        <hr />
        {shouts.map((shout, index) => {
          return (
            <textarea
              key={`${shout.note}-${index}`}
              value={shout.note}
              onChange={(e) => this.onNotesChange(e, index)}></textarea>
          );
        })}
        <button onClick={this.saveChanges}>Save Changes</button> */}
        <button onClick={this.saveChanges}>Save Changes</button>
        {this.state.editorState ? (
          <Editor
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}></Editor>
        ) : null}
        {this.state.editorState ? (
          <div
            dangerouslySetInnerHTML={{
              __html: draftToHtml(
                convertToRaw(this.state.editorState.getCurrentContent())
              ),
            }}></div>
        ) : null}
      </div>
    );
  }
}
