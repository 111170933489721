import React, { Component } from "react";
import { AppContext } from "../context/AppContext";
import { db, auth } from "../Firebase/firebase";
import { Link } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
export default class ShoutOuts extends Component {
  static contextType = AppContext;
  state = {
    notes: [],
  };
  componentDidMount() {
    db.collection("shoutouts")
      .doc(this.context.outlet)
      .get()
      .then((doc) => {
        this.setState({ shouts: doc.data().content });
      });

    db.collection("shoutouts")
      .where("outlet", "==", this.context.outlet)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState({
          notes: data.sort((a, b) => {
            return a.createdAt - b.createdAt;
          }),
          /* .filter((item) => !item.confirmed), */
        });
      });
  }
  render() {
    let { outlet } = this.context;
    return (
      <div className="notes-box dashboard">
        {outlet === "TCS" ? (
          <img
            className="outlet-logo inverted"
            src={require(`../../images/${outlet}.png`)}></img>
        ) : (
          <img
            id="inverse-logo"
            src={require(`../../images/${outlet}.png`)}></img>
        )}
        {!this.context.isMobile && auth.currentUser ? (
          <button
            id="logout-button"
            onClick={() => {
              auth.signOut();
              this.props.history.replace("/login");
            }}>
            logout
          </button>
        ) : null}
        <div className="music-nav">
          <Link to="/requests">Requests</Link>
          <Link to="/notes">Shout Outs</Link>
        </div>
        {/* <div className="note-grid">
          {this.state.notes.map((note) => {
            return (
              <div className="note-box">
                <p>{note.note}</p>
              </div>
            );
          })}
        </div> */}
        <div
          className="shouts-box"
          dangerouslySetInnerHTML={{
            __html: draftToHtml(this.state.shouts),
          }}></div>
      </div>
    );
  }
}
