import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { auth } from "./components/Firebase/firebase";
import PrivateRoute from "./components/util/PrivateRoute";
import Requests from "./components/admin/Requests";
import Login from "./components/util/Login";
import ShoutOuts from "./components/admin/ShoutOuts";
import Settings from "./components/admin/Settings";
import Confirmation from "./components/Customer/Confirmation";
import { Create } from "./components/Customer/Create";
import { AppContext } from "./components/context/AppContext";
import "./styles/main.css";
import AdminLogin from "./components/util/AdminLogin";
class App extends Component {
static contextType = AppContext;
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            this.context.setAdmin(true);
            this.props.history.replace("/admin");
          } else {
            this.context.setAdmin(false);
            this.props.history.replace("/requests");
          }
        });
      } 
    });

    /* auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.history.replace("/requests");
        this.setState({ user, auth: true });
      } else {
        this.setState({ auth: false });
      }
    }); */
  }
  render() {
    return (
      <div className="App">
         <Route exact path="/login" render={(props) => <Login {...props} />} />
         <Route exact path="/admin-login" render={(props) => <AdminLogin {...props} />} />
        <PrivateRoute
          path="/requests"
          component={Requests}
          auth={auth.currentUser ? auth.currentUser : false}
          admin={false}
        ></PrivateRoute>
        <PrivateRoute
          path="/notes"
          component={ShoutOuts}
          auth={auth.currentUser ? auth.currentUser : false}
          admin={false}
        ></PrivateRoute>
        <PrivateRoute
          path="/admin"
          component={Settings}
          auth={auth.currentUser ? auth.currentUser : false}
          admin={true}
        ></PrivateRoute>
        <Route exact path="/confirmation" component={Confirmation}></Route>
        <Route exact path="/" component={Create}></Route>
      </div>
    );
  }
}

export default withRouter(App);
