import React, { Component, createContext } from "react";
export const AppContext = createContext();

export default class AppContextProvider extends Component {
  state = {
    outlet: "TCS",
    admin: false,
    isMobile: window.innerWidth > 500 ? false : true,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    if (window.innerWidth <= 500) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };
  setAdmin = (bool) => {
    this.setState({ admin: bool });
  };
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setAdmin: this.setAdmin,
        }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
