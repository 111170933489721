import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { db, storage, auth } from "../Firebase/firebase";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import { message, DefaultProgress } from "flwww";
import ShoutOutsAdmin from "./ShoutOutsAdmin";

export default class Settings extends Component {
  static contextType = AppContext;
  state = {
    requests: [],
    date: new Date(),
    bgUrl: "",
    bgFile: null,
    bgProgress: 0,
    password: "",
  };
  componentDidMount() {
    let outletDoc = this.context.outlet === "TCS" ? "settingsTCS" : "settings";
    db.collection("requests")
      .where("outlet", "==", this.context.outlet)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState({
          requests: data.sort((a, b) => {
            return a.createdAt - b.createdAt;
          }),
          /* .filter((item) => !item.confirmed), */
        });
      });

    db.collection("settings")
      .doc(outletDoc)
      .onSnapshot((doc) => {
        this.setState({
          bgUrl: doc.data().mobileCustomerImg,
          passCode: doc.data().passCode,
        });
      });
  }
  onDateChange = (jsDate, dateString) => {
    this.setState({
      date: jsDate,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  savePassCode = () => {
    /* let user = auth.currentUser;
    user.updatePassword(this.state.password).then(() => {
      message("Password Change Success!", "success", 4);
    }); */
    message("Changing Password...", "success", 3);
    const adminData = {
      password: this.state.password,
      outlet: this.context.outlet,
    };
    fetch(
      "https://us-central1-ug-song-request.cloudfunctions.net/widgets/changeMusicianPassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(adminData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.message) {
          message("Password Change Success!", "success", 4);
        } else {
          console.log("Failed");
        }
      });
  };

  handleFileChange = (e) => {
    if (e.target.files[0]) {
      const imageFile = e.target.files[0];
      this.setState({
        bgFile: imageFile,
      });
    }
  };
  handleUpload = (e) => {
    let outletDoc = this.context.outlet === "TCS" ? "settingsTCS" : "settings";
    let outletFolder = this.context.outlet === "TCS" ? "TCS" : "cmpb";
    e.preventDefault();
    const { bgFile } = this.state;

    const uploadTask = storage
      .ref(`${outletFolder}/${bgFile.name}`)
      .put(bgFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //progress functions
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({
          bgProgress: progress,
        });
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref(outletFolder)
          .child(bgFile.name)
          .getDownloadURL()
          .then((url) => {
            // Insert url into an <img> tag to "download"
            db.collection("settings")
              .doc(outletDoc)
              .update({ mobileCustomerImg: url });
            this.setState({ bgUrl: url });
            message("Upload Success!", "success", 4);
          });
      }
    );
  };
  render() {
    let { requests, date } = this.state;
    return (
      <div className="admin-page">
        {this.context.outlet === "TCS" ? (
          <img
            className="outlet-logo inverted"
            src={require(`../../images/${this.context.outlet}.png`)}></img>
        ) : (
          <img
            id="inverse-logo"
            src={require(`../../images/${this.context.outlet}.png`)}></img>
        )}
        {auth.currentUser || true ? (
          <button
            id="logout-button"
            onClick={() => {
              auth.signOut();
              this.props.history.replace("/login");
            }}>
            logout
          </button>
        ) : null}
        {this.context.admin ? (
          <button
            id="link-button"
            onClick={() => {
              this.props.history.replace("/requests");
            }}>
            Requests
          </button>
        ) : null}
        {this.context.admin ? (
          <Tabs>
            <TabList>
              <Tab>History</Tab>
              <Tab>Images</Tab>
              <Tab>Admin</Tab>
              <Tab>Shout Outs</Tab>
            </TabList>

            <TabPanel>
              <div className="history-page">
                <DatePickerInput
                  value={date}
                  onChange={this.onDateChange}></DatePickerInput>
                <hr />
                <div className="request-grid">
                  {requests
                    .filter((request1) => {
                      return (
                        request1.createdAt.toDate().toDateString() ===
                        this.state.date.toDateString()
                      );
                    })
                    .map((request) => {
                      return (
                        <div key={request.id} className="request-box">
                          {/* <div
                className="cancel-btn"
                style={request.confirmed ? { display: "none" } : {}}
                onClick={() => this.toggleStatus(request.id, request.confirmed)}>
                <X></X>
                            </div> */}
                          <div className="song-dets">
                            <h4 className="artist">{request.artist}</h4>
                            <h4 className="song-name">{request.song}</h4>
                          </div>
                          <hr />
                          <div className="name-date">
                            <h4 className="req-name">{request.name}</h4>
                            <h4 className="created">
                              {moment(request.createdAt.toDate()).format(
                                "HH:mm"
                              )}
                            </h4>
                          </div>
                          {request.dedication !== "" ? <hr /> : null}
                          <div className="dedications">
                            {request.dedication}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="image-settings">
                <img src={this.state.bgUrl} alt="" />
                <div className="file-form-group">
                  <input
                    type="file"
                    onChange={(e) => this.handleFileChange(e)}
                  />
                  <button onClick={(e) => this.handleUpload(e)}>Upload</button>
                  {this.state.bgProgress ? (
                    <DefaultProgress
                      prcnt={this.state.bgProgress}></DefaultProgress>
                  ) : null}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="passcode-form">
                <label htmlFor="">Change Login Code</label>
                <input
                  type="text"
                  id="password"
                  onChange={this.onChange}
                  value={this.state.password}
                />
                <button onClick={this.savePassCode}>Save</button>
              </div>
            </TabPanel>
            <TabPanel>
              <ShoutOutsAdmin></ShoutOutsAdmin>
            </TabPanel>
          </Tabs>
        ) : (
          <div>You do not have permission to view this page.</div>
        )}
      </div>
    );
  }
}
