import firebase from "firebase";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCZinWEbbXJWqPW0c3jkc3HdBhTbe-WfwQ",
  authDomain: "ug-song-request.firebaseapp.com",
  projectId: "ug-song-request",
  storageBucket: "ug-song-request.appspot.com",
  messagingSenderId: "462706365121",
  appId: "1:462706365121:web:7b7b1501b2c70286977a40"
};

const fire = firebase.initializeApp(config);
export let auth = firebase.auth();
export let db = firebase.firestore();
export let lib = firebase.firestore;
export let storage = firebase.storage();
export default fire;