import React, { Component } from "react";
import { db, auth } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import RequestCard from "./RequestCard";
import { Switch } from "flwww";

export default class Requests extends Component {
  static contextType = AppContext;
  state = {
    requests: [],
    loading: true,
    sort: true,
    showConfirmed: false,
  };
  componentDidMount() {
    console.log(this.context.outlet);
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    db.collection("requests")
      .where("createdAt", ">=", new Date(year, month, day, 0, 0, 0, 0))
      .where("createdAt", "<=", new Date(year, month, day, 23, 0, 0, 0))
      .where("outlet", "==", this.context.outlet)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState({
          requests: data.sort((a, b) => {
            return b.createdAt - a.createdAt;
          }),
          /* .filter((item) => !item.confirmed), */
        });
      });
  }

  toggleStatus = (reqId, status) => {
    db.collection("requests").doc(reqId).update({
      confirmed: !status,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  changeSort = (sort) => {
    this.setState({ sort });
  };

  render() {
    console.log(auth);
    return (
      <div className="dashboard">
        {auth.currentUser || true ? (
          <button
            id="logout-button"
            onClick={() => {
              auth.signOut();
              this.props.history.replace("/login");
            }}>
            logout
          </button>
        ) : null}

        {this.context.admin ? (
          <button
            id="link-button"
            onClick={() => {
              this.props.history.replace("/admin");
            }}>
            Admin
          </button>
        ) : null}
        {this.context.outlet === "TCS" ? (
          <img
            id="inverse-logo"
            className="inverted"
            src={require(`../../images/TCS.png`)}></img>
        ) : (
          <img id="inverse-logo" src={require(`../../images/CM-PB.png`)}></img>
        )}
        <div className="music-nav">
          <Link to="/requests">Requests</Link>
          <Link to="/notes">Shout Outs</Link>
        </div>
        <div className="toggle-box">
          <label htmlFor="">Show Hidden Requests</label>
          <Switch
            name="showConfirmed"
            onChange={this.handleChange}
            checked={this.state.showConfirmed}></Switch>
        </div>
        <ul className="req-list">
          {this.state.requests
            .filter((request) => {
              if (this.state.showConfirmed) {
                return true;
              } else {
                return !request.hidden;
              }
            })
            .map((request) => {
              return (
                <RequestCard
                  key={`request-${request.song}-${request.name}`}
                  request={request}></RequestCard>
              );
            })}
        </ul>
        {this.state.requests.length === 0 ? (
          <div className="no-req">None at the moment.</div>
        ) : null}
      </div>
    );
  }
}
