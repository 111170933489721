import React from "react";
import moment from "moment";
import { db } from "../Firebase/firebase";
import { X } from "react-feather";
import { useDoubleTap } from "use-double-tap";

export default function RequestCard(props) {
  const toggleStatus = (reqId, status) => {
    db.collection("requests").doc(reqId).update({
      confirmed: !status,
    });
  };
  const toggleHidden = (reqId, status) => {
    db.collection("requests").doc(reqId).update({
      hidden: !status,
    });
  };
  const bind = useDoubleTap(
    (event) => {
      // Your action here
      toggleHidden(props.request.id, props.request.hidden);
    },
    300,
    {
      onSingleTap: () => {
        toggleStatus(props.request.id, props.request.confirmed);
      },
    }
  );
  let { request } = props;
  return (
    <li
    /* onTouchStart={(touchStartEvent) =>
          this.handleTouchStart(touchStartEvent)
        }
        onTouchMove={(touchMoveEvent) => this.handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => this.handleTouchEnd()}
        // The following event handlers are for mouse compatibility:
        onMouseDown={(mouseDownEvent) => this.handleMouseDown(mouseDownEvent)}
        onMouseMove={(mouseMoveEvent) => this.handleMouseMove(mouseMoveEvent)}
        onMouseUp={() => this.handleMouseUp()}
        onMouseLeave={() => this.handleMouseLeave()} */
    >
      <div>
        <div
          key={request.id}
          className="request-box"
          style={
            request.confirmed
              ? { backgroundColor: "#002A22", color: "#b0fdbc" }
              : request.hidden
              ? { backgroundColor: "#4F0910", color: "#ff9da3" }
              : {}
          }
          {...bind}>
          {/* <div
              className="cancel-btn"
              style={request.confirmed ? { display: "none" } : {}}
              onClick={() => this.toggleStatus(request.id, request.confirmed)}>
              <X></X>
            </div> */}
          <div className="song-dets">
            <h4 className="artist">{request.artist}</h4>
            <h4 className="song-name">{request.song}</h4>
          </div>
          <hr
            style={
              request.confirmed
                ? { borderColor: "#b0fdbc" }
                : request.hidden
                ? { borderColor: "#ff9da3" }
                : {}
            }
          />
          <div className="name-date">
            <h4 className="req-name">{request.name}</h4>
            <h4 className="created">
              {moment(request.createdAt.toDate()).format("HH:mm")}
            </h4>
          </div>
          {request.dedication !== "" ? (
            <hr
              style={
                request.confirmed
                  ? { borderColor: "#b0fdbc" }
                  : request.hidden
                  ? { borderColor: "#ff9da3" }
                  : {}
              }
            />
          ) : null}
          <div className="dedications">{request.dedication}</div>
        </div>
      </div>
    </li>
  );
}
